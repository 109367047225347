import React, { Fragment } from 'react';
import SbEditable from 'storyblok-react';
import Pages from '../load-page';
import Header from '../components/layouts/Header';
import Footer from '../components/layouts/Footer';
import Calendly from '../components/layouts/Calendly';

const loadStoryblokBridge = function (cb) {
  let script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = '//app.storyblok.com/f/storyblok-latest.js?t=sTTl8vs3vy5mPs5hX7hrXwtt'; // + process.env.STORYBLOK_PREVIEW_TOKEN;
  script.onload = cb;
  document.getElementsByTagName('head')[0].appendChild(script);
};

const getParam = function (val) {
  var result = '';
  var tmp = [];

  window.location.search
    .substr(1)
    .split('&')
    .forEach(function (item) {
      tmp = item.split('=');
      if (tmp[0] === val) {
        result = decodeURIComponent(tmp[1]);
      }
    });

  return result;
};

class StoryblokEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = { story: null, header: { content: {} }, footer: {} };
  }

  componentDidMount() {
    loadStoryblokBridge(() => {
      this.initStoryblokEvents();
    });
  }

  loadStory(payload) {
    window.storyblok.get(
      {
        slug: getParam('path'),
        version: 'draft',
        resolve_relations: 'case.related',
      },
      data => {
        this.setState({ story: data.story });
        this.loadHeader(data.story.lang);
        this.loadFooter();
      }
    );
  }

  loadHeader(lang) {
    window.storyblok.get(
      {
        id: `80976592`,
        version: 'draft',
      },
      data => {
        this.setState({ header: data.story });
      }
    );
  }

  loadFooter(lang) {
    window.storyblok.get(
      {
        id: `80976593`,
        version: 'draft',
      },
      data => {
        this.setState({ footer: data.story });
      }
    );
  }

  initStoryblokEvents() {
    this.loadStory({ storyId: getParam('path') });

    let sb = window.storyblok;

    sb.on(['change', 'published'], payload => {
      this.loadStory(payload);
    });

    sb.on('input', payload => {
      if (this.state.story && payload.story.id === this.state.story.id) {
        payload.story.content = sb.addComments(payload.story.content, payload.story.id);
        this.setState({ story: payload.story });
      }
    });

    sb.pingEditor(() => {
      if (sb.inEditor) {
        sb.enterEditmode();
      }
    });
  }

  render() {
    if (this.state.story == null) {
      return <div></div>;
    }

    let story = this.state.story;
    let content = this.state.story.content;
    let header = this.state.header.content;
    let footer = this.state.footer;

    return (
      <SbEditable content={content}>
        <Fragment>
          <Header blok={header} />
          <main id="main" className={story.content.component}>
            <Pages story={story} />
          </main>
          {story.slug !== 'designsubscription' && footer.content ? <Footer blok={footer.content} /> : <></>}
          {/* Design Choice to not have Calendly on E-commerce Design Audit Page */}
          {story.slug !== 'e-commerce-design-audit' ? <Calendly /> : <></>}
        </Fragment>
      </SbEditable>
    );
  }
}

export default StoryblokEntry;
